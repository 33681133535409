import axios, { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import _ from "lodash";

import { SESSION_STORAGE_KEY } from "./constants";
import { shouldMandatoryRefresh, shouldProactiveRefresh } from "./utils";
import { environment, getAuthEndpoint } from "../../config/config";
import { callWithRetry, reportError, toValidInteger } from "../../lib/utils";
import uuid from 'uuid-random';
import { RateLimitReachedError } from "./errors/RateLimitReachedError";
import { getBrowserAgentPlatform } from "../web/getBrowserAgentPlatform";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export type Storage = {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  [key: string]: any;
};

export type Session = {
  idToken: string;
  expiresAt: number;
};

export type TokenPayload = {
  alg: string;
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  jti: string;
  sub: string;
  prv: string;
  "https://hasura.io/jwt/claims": {
    "x-hasura-provider": `phone_number` | `email` | `apple` | `google`;
    "x-hasura-credential": string;
    "x-hasura-identity-id": string;
    "x-hasura-user-id": string;
    "x-hasura-default-role": string;
    "x-hasura-allowed-roles": string[];
  };
};

export type SessionDetails = {
  identityId: number;
  userId: number;
  tokenPayload: TokenPayload;
};

export type TokenResponse = {
  id_token: string;
  expires_at: number;
};

class Auth {
  protected static instance: Auth | null = null;
  protected storage: Storage;

  protected session: Session | null = null;
  protected onChangeSessionCallbacks: Record<
    string,
    (args: { session: Session; sessionDetails: SessionDetails } | null) => any
  > = {};
  protected refreshingPromise: Promise<TokenResponse | null> | null = null;

  public static getInstance(args?: { storage: Storage }) {
    if (Auth.instance) {
      return Auth.instance;
    }

    if (!args?.storage) {
      throw new Error(`Storage required in Auth`);
    }

    Auth.instance = new Auth(args);

    return Auth.instance;
  }

  public constructor(args: { storage: Storage }) {
    const { storage } = args;

    this.storage = storage;

    // this.getSession();

    Auth.instance = this;
  }

  public sendLoginCode = async (args: {
    provider: `phone_number` | `email`;
    credential: string;
    recaptchaToken?: string | null | undefined;
    device?: Record<string, any> | null | undefined;
    userAgent?: string | null | undefined;
  }): Promise<void> => {
    const { provider, credential, recaptchaToken, device, userAgent } = args;

    const url = getAuthEndpoint();
    const deviceHeaders = getDeviceHeaders();

    let resp: AxiosResponse<any> | null | undefined = null;

    resp = await callWithRetry(
      async ({ lastError, abortRetries }) => {
        if ((lastError?.response?.status ?? 99999) < 500) {
          abortRetries();
        }

        return await axios
          .post<any, AxiosResponse<any>>(
            `${url}/auth/send-login-code/users/${provider}`,
            {
              credential,
              recaptcha_token: recaptchaToken,
              device,
              user_agent: userAgent,
            },
            {
              headers: {
                ...deviceHeaders,
                // "X-Source": "WEB",
                // "X-Platform": "web",
                // "X-Operating-System": getBrowserAgentPlatform(),
              },
            }
          )
          .catch((e) => {
            if (e?.response?.status === 403 && e?.response?.data?.code === 'RATE_LIMIT_REACHED') {
              throw new RateLimitReachedError();
            }

            reportError(e, {
              provider,
              credential,
            });

            throw e;
          });
      },
      environment.isProduction ? 3 : 0,
      500,
      false
    );

    if (resp?.status !== 200) {
      // Throw a better error here
      throw new Error("Unexpected Error");
    }
  };

  public verifyLoginCode = async (args: {
    provider: `phone_number` | `email`;
    credential: string;
    recaptchaToken?: string | null | undefined;
    code: number;
    device?: Record<string, any> | null | undefined;
    userAgent?: string | null | undefined;
    onBeforeLogin?: (args: { tokenResponse: TokenResponse }) => any;
  }): Promise<{
    status: `INVALID_LOGIN_CODE` | `ERROR` | `SUCCESS`;
    tokenResponse?: TokenResponse | null;
  }> => {
    const { provider, credential, recaptchaToken, code, device, userAgent, onBeforeLogin = async () => {} } = args;

    const url = getAuthEndpoint();
    const deviceHeaders = getDeviceHeaders();

    let resp: AxiosResponse<TokenResponse> | null | undefined = null;
    let status: `INVALID_LOGIN_CODE` | `ERROR` | `SUCCESS` = `ERROR`;

    try {
      resp = await callWithRetry(
        async ({ lastError, abortRetries }) => {
          if ((lastError?.response?.status ?? 99999) < 500) {
            abortRetries();
          }

          return await axios
            .post<any, AxiosResponse<TokenResponse>>(
              `${url}/auth/verify-login-code/users/${provider}`,
              {
                credential,
                code,
                recaptcha_token: recaptchaToken,
                device,
                user_agent: userAgent,
              },
              {
                headers: {
                  ...deviceHeaders,
                  // "X-Source": "WEB",
                  // "X-Platform": "web",
                  // "X-Operating-System": getBrowserAgentPlatform(),
                },
              }
            )
            .catch((e) => {
              if (e?.response?.status !== 401) {
                reportError(e, {
                  provider,
                  credential,
                  code,
                });
              }

              throw e;
            });
        },
        environment.isProduction ? 3 : 0,
        500,
        false
      );
    } catch (e: unknown) {
      if (e?.response?.status === 403 && e?.response?.data?.code === 'RATE_LIMIT_REACHED') {
        throw new RateLimitReachedError();
      } else if (e?.response?.status === 401) {
        status = `INVALID_LOGIN_CODE`;
      } else {
        status = `ERROR`;
      }
    }

    if (resp?.data?.id_token) {
      status = `SUCCESS`;

      const tokenResponse = resp?.data as TokenResponse;

      await onBeforeLogin({ tokenResponse });

      await this.setSession({
        idToken: tokenResponse.id_token,
        expiresAt: tokenResponse.expires_at,
      });

      return {
        status,
        tokenResponse,
      };
    }

    return {
      status,
    };
  };

  public exchangeToken = async (args: {
    provider: `apple` | `google` | `cognito`;
    token?: string | null | undefined;
    authorizationCode?: string | null | undefined;
    attributes?: Record<string, unknown>;
  }): Promise<TokenResponse | null> => {
    const { provider, token, authorizationCode, attributes } = args;
    const url = getAuthEndpoint();

    let resp: AxiosResponse<TokenResponse> | null | undefined = null;

    try {
      resp = await callWithRetry(
        async ({ lastError, abortRetries }) => {
          if ((lastError?.response?.status ?? 99999) < 500) {
            abortRetries();
          }

          return await axios
            .post<any, AxiosResponse<TokenResponse>>(
              `${url}/auth/tokens/exchange/users/${provider}`,
              {
                provider,
                token,
                auth_code: authorizationCode,
                attributes,
              }
            )
            .catch((e) => {
              reportError(e, {
                provider,
                token,
                authorizationCode,
              });

              throw e;
            });
        },
        environment.isProduction ? 3 : 0,
        500,
        false
      );
    } catch (e: unknown) {
      // We lready reported the error, so we just don't return a tokenResponse
    }

    if (resp?.data?.id_token) {
      const tokenResponse = resp?.data as TokenResponse;

      await this.setSession({
        idToken: tokenResponse.id_token,
        expiresAt: tokenResponse.expires_at,
      });

      return tokenResponse;
    }

    return null;
  };

  public refreshSession = async (): Promise<TokenResponse | null> => {
    if (this.refreshingPromise) {
      return this.refreshingPromise;
    }

    const refresh = async () => {
      const url = getAuthEndpoint();
      const deviceHeaders = getDeviceHeaders();

      let resp: AxiosResponse<TokenResponse> | null | undefined = null;

      try {
        resp = await callWithRetry(
          async ({ lastError, abortRetries }) => {
            if ((lastError?.response?.status ?? 99999) < 500) {
              abortRetries();
            }

            return await axios
              .post<any, AxiosResponse<TokenResponse>>(
                `${url}/auth/tokens/refresh`,
                {},
                {
                  headers: {
                    ...deviceHeaders,
                    Authorization: `Bearer ${this.session?.idToken}`,
                    // "X-Source": "WEB",
                    // "X-Platform": "web",
                    // "X-Operating-System": getBrowserAgentPlatform(),
                  },
                }
              )
              .catch((e) => {
                reportError(e, {});

                throw e;
              });
          },
          environment.isProduction ? 1 : 0,
          500,
          false
        );
      } catch (e: unknown) {}

      if (resp?.data?.id_token) {
        const tokenResponse = resp?.data as TokenResponse;

        await this.setSession({
          idToken: tokenResponse.id_token,
          expiresAt: tokenResponse.expires_at,
        });

        this.refreshingPromise = null;

        return tokenResponse;
      }

      this.clearSession();

      this.refreshingPromise = null;

      return null;
    };

    this.refreshingPromise = refresh();

    return this.refreshingPromise;
  };

  public invalidateSession = async (): Promise<void> => {
    await this.clearSession();

    // TODO Attempt to invalidate using auth endpoint
    //  - NOTE: Requires blacklisting to be enabled (which is isn't at the time of writing, until
    //          we figure out how to use it without accidentally causing UX issues)

    this.triggerOnChangeSession(null);
  };

  public storeSession = async (idToken: string, expiresAt: number) => {
    const session: Session = {
      idToken,
      expiresAt,
    };

    const sessionStr = JSON.stringify(session);

    await this.storage.setItem(SESSION_STORAGE_KEY, sessionStr);
  };

  public getSessionFromStorage = async () => {
    const sessionStr = await this.storage.getItem(SESSION_STORAGE_KEY);

    const session: Session =
      typeof sessionStr === `string` ? JSON.parse(sessionStr) ?? {} : null;

    if (
      typeof session?.idToken !== `string` ||
      typeof session?.expiresAt !== `number`
    ) {
      return null;
    }

    return session;
  };

  public getSession = async (): Promise<{
    session: Session;
    sessionDetails: SessionDetails;
  } | null> => {
    try {
      if (!this.session) {
        this.session = await this.getSessionFromStorage();
      }

      if (!this.session) {
        this.triggerOnChangeSession(null);

        return null;
      }

      //
      // Temporary Simplification of Token Refresh until we get a handle on the 500 refresh errors and hanging web UI
      //
      if (shouldProactiveRefresh(this.session.expiresAt) || shouldMandatoryRefresh(this.session.expiresAt)) {
        await this.refreshSession();

        if (this.session) {
          const sessionDetails = makeSessionDetails(this.session);

          this.triggerOnChangeSession({ session: this.session, sessionDetails });

          return {
            session: this.session,
            sessionDetails,
          };
        }

        this.invalidateSession();

        return null;
      } else {
        const sessionDetails = makeSessionDetails(this.session);

        this.triggerOnChangeSession({ session: this.session, sessionDetails });

        return {
          session: this.session,
          sessionDetails,
        };
      }
      //
      //

      // if (shouldProactiveRefresh(this.session.expiresAt)) {
      //   // Don't await, since failure here isn't a big issue, we're just proactively trying to refresh
      //   this.refreshSession();

      //   const sessionDetails = makeSessionDetails(this.session);

      //   return {
      //     session: this.session,
      //     sessionDetails,
      //   };
      // } else if (shouldMandatoryRefresh(this.session.expiresAt)) {
      //   // Refresh immediately
      //   await this.refreshSession();

      //   const sessionDetails = makeSessionDetails(this.session);

      //   return {
      //     session: this.session,
      //     sessionDetails,
      //   };
      // } else {
      //   const sessionDetails = makeSessionDetails(this.session);

      //   this.triggerOnChangeSession({ session: this.session, sessionDetails });

      //   return {
      //     session: this.session,
      //     sessionDetails,
      //   };
      // }
    } catch (e: unknown) {
      this.reportErrorAndClearSession(e);

      return null;
    }
  };

  public setSession = async (args: { idToken: string; expiresAt: number }) => {
    const { idToken, expiresAt } = args;

    this.session = {
      idToken,
      expiresAt,
    };

    await this.storeSession(idToken, expiresAt);

    const sessionDetails = makeSessionDetails(this.session);

    this.triggerOnChangeSession({ session: this.session, sessionDetails });
  };

  public clearSession = async () => {
    this.session = null;

    await this.storage.removeItem(SESSION_STORAGE_KEY);
  };

  //
  // Listeners
  //

  public onChangeSession = (
    cb: (
      args: { session: Session; sessionDetails: SessionDetails } | null
    ) => any
  ) => {
    const listenerId = uuid();
    this.onChangeSessionCallbacks[listenerId] = cb;

    return () => {
      this.offChangeSession(listenerId);
    };
  };

  public offChangeSession = (id?: string) => {
    if (id) {
      delete this.onChangeSessionCallbacks[id];
    } else {
      this.onChangeSessionCallbacks = {};
    }
  };

  public triggerOnChangeSession = (
    args: { session: Session; sessionDetails: SessionDetails } | null
  ) => {
    _.map(this.onChangeSessionCallbacks, (cb) => {
      cb(args);
    });
  };

  //
  // Utils
  //

  protected reportErrorAndClearSession = async (e: unknown) => {
    let context:
      | {
          session: Session;
          sessionDetails: SessionDetails;
        }
      | object = {};

    try {
      if (this.session) {
        const sessionDetails = makeSessionDetails(this.session);

        context = {
          session: this.session,
          sessionDetails,
        };
      }
    } catch (ee) {}

    reportError(e, context);

    await this.clearSession();
  };
}

export function makeSessionDetails(session: Session): SessionDetails {
  const tokenPayloadWithJson = jwt_decode(session.idToken) as Omit<
    TokenPayload,
    "https://hasura.io/jwt/claims"
  > & {
    "https://hasura.io/jwt/claims": string;
  };

  const tokenPayload: TokenPayload = {
    ...tokenPayloadWithJson,
    "https://hasura.io/jwt/claims": JSON.parse(
      tokenPayloadWithJson["https://hasura.io/jwt/claims"]
    ) as TokenPayload["https://hasura.io/jwt/claims"],
  };

  return {
    identityId: toValidInteger(tokenPayload?.sub) as number,
    userId: toValidInteger(
      tokenPayload?.["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
    ) as number,
    tokenPayload,
  };
}

export { Auth };
