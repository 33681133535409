import { useCallback, useEffect, useMemo, useState } from "react";

import { useMultiAuth } from "./useMultiAuth";
import { parsePhoneNumber, toValidInteger } from "../../lib/utils";
import { useRemember } from "../../lib/useRemember";
import { TokenResponse } from "./Auth";
import { environment } from "../../config/config";

export type State = {
  uiState: `ENTER_CREDENTIAL` | `OTP_SENT`;
  isSending: boolean;
  isVerifying: boolean;
  isSuccess: boolean;
  errorMessage: string | null;
  credential: string;
  loginCode: string;
};

export type Args = {
  provider: `phone_number` | `email`;
  onCancel?: () => any;
};

function usePasswordlessState(args: Args) {
  const { provider, onCancel = () => {} } = args;

  const auth = useMultiAuth();

  const [state, setState] = useState<State>({
    uiState: `ENTER_CREDENTIAL`,
    isSending: false,
    isVerifying: false,
    isSuccess: false,
    errorMessage: null,
    credential: ``,
    loginCode: ``,
  });

  const { credential, loginCode } = state;

  let isCredentialValid = false;
  if (provider === `phone_number`) {
    isCredentialValid = parsePhoneNumber(credential).isValid;
  } else if (provider === `email`) {
    isCredentialValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.+[A-Za-z]+/.test(
      credential ?? ""
    );
  }

  const isLoginCodeValid = loginCode.length === 4;

  const lastKnownCredential = useRemember({
    key: `auth.last_known_credential.${provider}.v1`,
  });

  useEffect(() => {
    if (lastKnownCredential.isLoaded) {
      lastKnownCredential.getValue().then((credential) => {
        if (typeof credential === `string` && credential.length > 0) {
          setState((state) => ({
            ...state,
            credential,
          }));
        }
      });
    }
  }, [lastKnownCredential.isLoaded]);

  const resetState = useCallback(() => {
    setState((state) => ({
      ...state,
      uiState: `ENTER_CREDENTIAL`,
      isSending: false,
      isVerifying: false,
      isSuccess: false,
      errorMessage: null,
      // credential: ``,
      loginCode: ``,
    }));
  }, []);

  const setCredential = useCallback((args: {
    credential: string,
  }) => {
    const { credential } = args;

    setState((state) => ({
      ...state,
      credential,
    }));
    lastKnownCredential.setValue(credential);
  }, []);

  const setLoginCode = useCallback((args: {
    loginCode: string,
  }) => {
    const { loginCode } = args;

    setState((state) => ({
      ...state,
      loginCode,
    }));
  }, []);

  const sendLoginCode = useCallback(
    async (args: {
      credential: string;
      recaptchaToken?: string | null | undefined;
      device?: Record<string, any> | null | undefined;
      userAgent?: string | null | undefined;
    }) => {
      const { credential, recaptchaToken, device, userAgent } = args;

      let isCredentialValid = false;
      let finalCredential = credential;

      if (provider === `phone_number`) {
        const parsedPhoneNumber = parsePhoneNumber(credential);
        isCredentialValid = parsedPhoneNumber.isValid;
        finalCredential = parsedPhoneNumber.e164 ?? ``;
      } else if (provider === `email`) {
        isCredentialValid =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.+[A-Za-z]+/.test(
            credential ?? ""
          );
      }

      if (!isCredentialValid) return;

      try {
        setState((state) => ({
          ...state,
          isSending: true,
          errorMessage: null,
        }));

        await auth.sendLoginCode({
          provider,
          credential: finalCredential as string,
          recaptchaToken,
          device,
          userAgent,
        });

        setState((state) => ({
          ...state,
          uiState: `OTP_SENT`,
          isSending: false,
          errorMessage: null,
        }));
      } catch (e: unknown) {
        const humanMessage = e?.humanMessage;
        const eMsg = e?.message;

        let errorMessage = "Unknown Error, Try Again";
        if (humanMessage) {
          errorMessage = humanMessage;
        } else if (!environment.isProduction) {
          errorMessage = eMsg ?? errorMessage;
        }

        setState((state) => ({
          ...state,
          isSending: false,
          errorMessage,
        }));
      }
    },
    [provider, auth.sendLoginCode]
  );

  const verifyLoginCode = useCallback(
    async (args: {
      credential: string;
      recaptchaToken?: string | null | undefined;
      code: number;
      device?: Record<string, any> | null | undefined;
      userAgent?: string | null | undefined;
      onBeforeLogin?: (args: { tokenResponse: TokenResponse }) => any;
    }): Promise<ReturnType<typeof auth.verifyLoginCode> | null> => {
      const {
        credential,
        recaptchaToken,
        code,
        device,
        userAgent,
        onBeforeLogin = async () => {},
      } = args;

      let isCredentialValid = false;
      let finalCredential = credential;

      const isLoginCodeValid = String(code).length === 4;

      if (provider === `phone_number`) {
        const parsedPhoneNumber = parsePhoneNumber(credential);
        isCredentialValid = parsedPhoneNumber.isValid;
        finalCredential = parsedPhoneNumber.e164 ?? ``;
      } else if (provider === `email`) {
        isCredentialValid =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.+[A-Za-z]+/.test(
            credential ?? ""
          );
      }

      if (!isCredentialValid || !isLoginCodeValid) return null;

      try {
        setState((state) => ({
          ...state,
          isVerifying: true,
          errorMessage: null,
        }));

        const resp = await auth.verifyLoginCode({
          provider,
          credential: finalCredential,
          recaptchaToken,
          code,
          device,
          userAgent,
          onBeforeLogin,
        });

        switch (resp.status) {
          case `ERROR`:
            setState((state) => ({
              ...state,
              isVerifying: false,
              isSuccess: false,
              errorMessage: "Unknown Error, Try Again",
              loginCode: ``,
            }));

            break;
          case `INVALID_LOGIN_CODE`:
            setState((state) => ({
              ...state,
              isVerifying: false,
              isSuccess: false,
              errorMessage: `Invalid Login Code`,
              loginCode: ``,
            }));

            break;
          default:
            auth.providers.setLastLoggedIn(provider);

            setState((state) => ({
              ...state,
              isVerifying: false,
              isSuccess: true,
              errorMessage: null,
              loginCode: ``,
            }));
        }

        return resp;
      } catch (e: unknown) {
        const humanMessage = e?.humanMessage;
        const eMsg = e?.message;

        let errorMessage = "Unknown Error, Try Again";
        if (humanMessage) {
          errorMessage = humanMessage;
        } else if (!environment.isProduction) {
          errorMessage = eMsg ?? errorMessage;
        }

        setState((state) => ({
          ...state,
          isVerifying: false,
          errorMessage,
          loginCode: ``,
        }));

        return null;
      }
    },
    [provider, auth.verifyLoginCode, auth.providers.setLastLoggedIn]
  );

  return useMemo(() => {
    return {
      state,
      isCredentialValid,
      isLoginCodeValid,
      resetState,
      setCredential,
      setLoginCode,
      sendLoginCode,
      verifyLoginCode,
    };
  }, [
    state,
    isCredentialValid,
    isLoginCodeValid,
    resetState,
    setCredential,
    setLoginCode,
    sendLoginCode,
    verifyLoginCode,
  ]);
}

export { usePasswordlessState };
