import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { getGraphqlEndpoint } from "../../config/config";
import { UserDocument, UserQuery, UserQueryVariables } from "../../generatedQueries";
import { request } from 'graphql-request';
import uuid from 'uuid-random';
import queryKeys from "../query-keys";
import useCachePrimer from "../useCachePrimer";
import { Auth } from "../../auth/core/Auth";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import useRemember from "../../lib/useRemember";
import * as Sentry from "@sentry/react";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export type User = UserQuery["users"][number];

export type SentryUserContext = {
  id?: string;
  cognito_identity_id?: string | null | undefined;
  cognito_username?: string | null | undefined;
  first_name?: string | null | undefined;
  last_name?: string | null | undefined;
  mxm_customer_id?: number | null | undefined;
};

export function useSentryUserContext(
  user?: User | null | undefined
): SentryUserContext {
  const { session } = useMultiAuth();

  // const { cachedOtpPhoneNumber } = useAuth();
  const {
    value: lastKnownSentryUserContext,
    setValue: setLastKnownSentryUserContext,
  } = useRemember<SentryUserContext>({
    namespace: `sentry-v-1`,
    key: `last-known-user-context`,
  });

  const identityId = session?.sessionDetails.identityId;
  // NOTE: Sentry requires the ID, so we just generate a random UUID if we don't know the last numeric ID yet
  const id = _.toString(
    session?.sessionDetails.userId ??
      user?.id ??
      lastKnownSentryUserContext?.id ??
      uuid()
  );
  const cognitoIdentityId =
    user?.cognito_identity_id /*??
    lastKnownSentryUserContext?.cognito_identity_id*/;
  const firstName = user?.first_name /*?? lastKnownSentryUserContext?.first_name*/;
  const lastName = user?.last_name /*?? lastKnownSentryUserContext?.last_name*/;
  const cognitoUsername =
    user?.phone_number /*??
    cachedOtpPhoneNumber ??
    lastKnownSentryUserContext?.cognito_username*/;
  const mxmCustomerId =
    user?.mxm_customer_id /*?? lastKnownSentryUserContext?.mxm_customer_id*/;

  const sentryUserContext = useMemo(
    () => ({
      id,
      identity_id: identityId,
      // cognito_identity_id: cognitoIdentityId,
      first_name: firstName,
      last_name: lastName,
      // cognito_username: cognitoUsername,
      mxm_customer_id: mxmCustomerId,
      phone_number: cognitoUsername,
    }),
    [
      id,
      identityId,
      // cognitoIdentityId,
      firstName,
      lastName,
      cognitoUsername,
      mxmCustomerId,
    ]
  );

  useEffect(() => {
    setLastKnownSentryUserContext({
      ...lastKnownSentryUserContext,
      ...sentryUserContext,
    });
  }, [id, identityId, firstName, lastName, mxmCustomerId, cognitoUsername]);

  return sentryUserContext;
}

type Options = Omit<
  UseQueryOptions<UserQuery["users"], unknown, UserQueryVariables>,
  "queryFn"
>;

export async function fetchUser(
  id: number,
  signal?: AbortSignal | null | undefined
) {
  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  return (await request({
    url: getGraphqlEndpoint() as string,
    document: UserDocument,
    variables: {},
    requestHeaders,
    signal,
  })).users;
}

export default function useUser(options?: Options) {
  const auth = useMultiAuth();

  const cachePrimer = useCachePrimer();

  const query = useQuery<UserQuery["users"]>(
    queryKeys.User(auth.session?.sessionDetails.userId ?? -1),
    async ({ signal }) => {
      return await fetchUser(auth.session?.sessionDetails.userId ?? -1, signal);
    },
    {
      enabled: !!auth.session,
      staleTime: 0, //5 * 60 * 1000, // 5min

      onSuccess(data) {
        // const wallet = data?.[0].wallet;

        // if (wallet) {
        //   cachePrimer.Wallet.replaceByUserId(wallet);
        // }
      },

      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
        // else {
        //   handleError(error);
        // }
      },
    }
  );
  // useThrowQueryErrorToBoundary(query);

  const user: User | null = useMemo(() => {
    if (!query.data?.[0]) return null;

    return {
      ...query.data[0],
    };
  }, [query.data]);

  const sentryUserContext = useSentryUserContext(user);
  // const { lastKnownClientDevice, refresh: refreshLastKnownClientDevice } =
  //   useLastKnownClientDevice();

  // Hackishy try to refresh this anytime the user changes until we move all this up into a context provider
  // const lastKnownClientDevicePresent = !!lastKnownClientDevice;
  // useEffect(() => {
  //   if (user && !lastKnownClientDevicePresent) {
  //     refreshLastKnownClientDevice();
  //   }
  // }, [user]);

  useEffect(() => {
    if (sentryUserContext) {
      Sentry.setUser(sentryUserContext);
    }

    // if (lastKnownClientDevice) {
    //   Sentry.Native.setExtra("last_known_client_device", lastKnownClientDevice);

    //   if (!isProduction) {
    //     // console.log(
    //     //   "Sentry Last Known Client Device Context",
    //     //   lastKnownClientDevice
    //     // );
    //   }
    // }
  }, [sentryUserContext/*, lastKnownClientDevice*/]);

  return {
    query,
    user,
  };
}
